import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  addDoc,
  serverTimestamp,
  getDocs,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collectionData, docData } from 'rxfire/firestore';

import { DB, AUTH } from './firebase';
// PATH
import { PATH_CATEGORY } from './firebase-paths';

const createProductCategory = async (data) => {
  let email = null;
  let uid = null;

  await onAuthStateChanged(AUTH, async (user) => {
    if (user) {
      email = user.email;
      uid = user.uid;
    }
  });

  const createProductCategoryPayload = {
    ...data,
    categoryCreateDate: serverTimestamp(),
    categoryUpdateDate: serverTimestamp(),
    categoryAdminUserKey: uid,
    categoryAdminEmail: email,
  };

  const docRef = await addDoc(PATH_CATEGORY.root, createProductCategoryPayload);

  console.log('Document written with ID: ', docRef.id);

  return docRef;
};

const getProductCategoryList = () => collectionData(PATH_CATEGORY.root, { idField: 'categoryKey' });

const getProductCategoryDoc = (docKey) => docData(PATH_CATEGORY.doc(docKey), { idField: 'categoryKey' });

const deleteProductCategory = (categoryKey) => deleteDoc(PATH_CATEGORY.doc(categoryKey));

const updateProductCategory = (categoryKey, data) =>
  updateDoc(PATH_CATEGORY.doc(categoryKey), {
    ...data,
    categoryUpdateDate: serverTimestamp(),
  });

// const getProductCategoryList = async () => {
//   const querySnapshot = await getDocs(collection(DB, PATH_CATEGORY.root));

//   console.log(querySnapshot);

//   return querySnapshot.docs.map((doc) => ({
//     id: doc.id,
//     ...doc.data(),
//   }));
// };

export {
  createProductCategory,
  getProductCategoryList,
  getProductCategoryDoc,
  deleteProductCategory,
  updateProductCategory,
};
