import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { map } from 'rxjs';
// service
import {
  createProductTransport,
  getProductTransportList,
  getProductTransportDoc,
  deleteProductTransport,
  updateProductTransport,
} from '../../service/transport';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  transports: [],
  transport: null,
  createTransport: false,
  updateTransport: false,
  deleteTransport: false,
  sortBy: null,
};

const slice = createSlice({
  name: 'transport',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CREATE TRANSPORT
    createProductTransportSuccess(state, action) {
      state.isLoading = false;
      state.createTransport = true;
    },

    // GET TRANSPORTS
    getProductTransportsSuccess(state, action) {
      state.isLoading = false;
      state.updateTransport = false;
      state.deleteTransport = false;
      state.transports = action.payload;
    },

    // GET TRANSPORT
    getProductTransportSuccess(state, action) {
      state.isLoading = false;
      state.updateTransport = false;
      state.deleteTransport = false;
      state.transport = action.payload;
    },

    // UPDATE TRANSPORT
    updateProductTransportSuccess(state, action) {
      state.isLoading = false;
      state.updateTransport = true;
    },

    // DELETE TRANSPORT
    deleteProductTransportSuccess(state, action) {
      state.isLoading = false;
      state.deleteTransport = true;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function createTransport(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      createProductTransport(data).subscribe((transportRef) => {
        dispatch(slice.actions.createProductTransportSuccess(transportRef));
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTransports() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      getProductTransportList().subscribe((transportList) => {
        dispatch(slice.actions.getProductTransportsSuccess(transportList));
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTransport(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      getProductTransportDoc(id).subscribe((transportDoc) => {
        dispatch(slice.actions.getProductTransportSuccess(transportDoc));
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateTransport(id, data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      updateProductTransport(id, data).subscribe((transportDoc) => {
        dispatch(slice.actions.updateProductTransportSuccess());
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteTransport(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      deleteProductTransport(id).subscribe((transportDoc) => {
        dispatch(slice.actions.deleteProductTransportSuccess());
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
