import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { map } from 'rxjs';
// service
import {
  createProductTheme,
  getProductThemeList,
  getProductThemeDoc,
  deleteProductTheme,
  updateProductTheme,
} from '../../service/theme';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  themes: [],
  theme: null,
  createTheme: false,
  updateTheme: false,
  deleteTheme: false,
  sortBy: null,
};

const slice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CREATE THEME
    createProductThemeSuccess(state, action) {
      state.isLoading = false;
      state.createTheme = true;
    },

    // GET THEMES
    getProductThemesSuccess(state, action) {
      state.isLoading = false;
      state.updateTheme = false;
      state.deleteTheme = false;
      state.themes = action.payload;
    },

    // GET THEME
    getProductThemeSuccess(state, action) {
      state.isLoading = false;
      state.updateTheme = false;
      state.deleteTheme = false;
      state.theme = action.payload;
    },

    // UPDATE THEME
    updateProductThemeSuccess(state, action) {
      state.isLoading = false;
      state.updateTheme = true;
    },

    // DELETE THEME
    deleteProductThemeSuccess(state, action) {
      state.isLoading = false;
      state.deleteTheme = true;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function createTheme(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      createProductTheme(data).subscribe((themeRef) => {
        dispatch(slice.actions.createProductThemeSuccess(themeRef));
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getThemes() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      getProductThemeList().subscribe((themeList) => {
        dispatch(slice.actions.getProductThemesSuccess(themeList));
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getThemesDropDown() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      getProductThemeList()
        .pipe(
          map((themeList) => {
            return themeList.map((theme) => {
              return {
                th: `${theme.themeThaiName}`,
                en: `${theme.themeEngName}`,
              };
            });
          })
        )
        .subscribe((themeList) => {
          dispatch(slice.actions.getProductThemesSuccess(themeList));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTheme(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      getProductThemeDoc(id).subscribe((themeDoc) => {
        dispatch(slice.actions.getProductThemeSuccess(themeDoc));
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateTheme(id, data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      updateProductTheme(id, data).subscribe((themeDoc) => {
        dispatch(slice.actions.updateProductThemeSuccess());
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteTheme(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      deleteProductTheme(id).subscribe((themeDoc) => {
        dispatch(slice.actions.deleteProductThemeSuccess());
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
