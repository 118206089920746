import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import categoryReducer from './slices/category';
import tagReducer from './slices/tag';
import sizeReducer from './slices/size';
import themeReducer from './slices/theme';
import durationReducer from './slices/duration';
import paymentReducer from './slices/payment';
import transportReducer from './slices/transport';
import reviewReducer from './slices/review';
import couponReducer from './slices/coupon';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  category: categoryReducer,
  tag: tagReducer,
  size: sizeReducer,
  theme: themeReducer,
  duration: durationReducer,
  payment: paymentReducer,
  transport: transportReducer,
  review: reviewReducer,
  coupon: couponReducer,
});

export { rootPersistConfig, rootReducer };
