import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// date-dns
import enGB from 'date-fns/locale/en-GB';

// ----------------------------------------------------------------------

RHFDatePicker.propTypes = {
  name: PropTypes.string,
};

export default function RHFDatePicker({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
          <DateTimePicker
            {...field}
            slotProps={{ textField: { fullWidth: true } }}
            fullWidth
            error={!!error}
            helperText={error?.message}
            {...other}
          />
        </LocalizationProvider>
      )}
    />
  );
}
