import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { map } from 'rxjs';
// service
import {
  createProductSize,
  getProductSizeList,
  getProductSizeDoc,
  deleteProductSize,
  updateProductSize,
} from '../../service/size';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  sizes: [],
  size: null,
  createSize: false,
  updateSize: false,
  deleteSize: false,
  sortBy: null,
};

const slice = createSlice({
  name: 'size',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CREATE SIZE
    createProductSizeSuccess(state, action) {
      state.isLoading = false;
      state.createSize = true;
    },

    // GET SIZES
    getProductSizesSuccess(state, action) {
      state.isLoading = false;
      state.updateSize = false;
      state.deleteSize = false;
      state.sizes = action.payload;
    },

    // GET SIZE
    getProductSizeSuccess(state, action) {
      state.isLoading = false;
      state.updateSize = false;
      state.deleteSize = false;
      state.size = action.payload;
    },

    // UPDATE SIZE
    updateProductSizeSuccess(state, action) {
      state.isLoading = false;
      state.updateSize = true;
    },

    // DELETE SIZE
    deleteProductSizeSuccess(state, action) {
      state.isLoading = false;
      state.deleteSize = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function createSize(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      createProductSize(data).subscribe((sizeRef) => {
        dispatch(slice.actions.createProductSizeSuccess(sizeRef));
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSizesDropDown() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      getProductSizeList()
        .pipe(
          map((sizes) => {
            return sizes.map((size) => {
              return {
                th: `${size.sizeThaiName} - (${size.sizeChest}/${size.sizeWaist}/${size.sizeHip})`,
                en: `${size.sizeEngName} - (${size.sizeChest}/${size.sizeWaist}/${size.sizeHip})`,
              };
            });
          })
        )
        .subscribe((sizeList) => {
          dispatch(slice.actions.getProductSizesSuccess(sizeList));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSizes() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      getProductSizeList().subscribe((sizeList) => {
        dispatch(slice.actions.getProductSizesSuccess(sizeList));
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSize(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      getProductSizeDoc(id).subscribe((sizeDoc) => {
        dispatch(slice.actions.getProductSizeSuccess(sizeDoc));
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateSize(id, data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      updateProductSize(id, data).subscribe((sizeDoc) => {
        dispatch(slice.actions.updateProductSizeSuccess());
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteSize(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      deleteProductSize(id).subscribe((sizeDoc) => {
        dispatch(slice.actions.deleteProductSizeSuccess());
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
