import { initializeApp } from 'firebase/app';

import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';

import { getFirestore, collection, doc, getDoc, setDoc } from 'firebase/firestore';

import { getStorage, ref } from 'firebase/storage';

//

import { FIREBASE_API } from '../config';

export const firebaseApp = initializeApp(FIREBASE_API);

export const AUTH = getAuth(firebaseApp);

export const DB = getFirestore(firebaseApp);

export const STORAGE = getStorage(firebaseApp);
