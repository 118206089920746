import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  addDoc,
  serverTimestamp,
  getDocs,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collectionData, docData } from 'rxfire/firestore';

import { DB, AUTH } from './firebase';
// PATH
import { PATH_THEME } from './firebase-paths';

const getProductThemeList = () => collectionData(PATH_THEME.root, { idField: 'themeKey' });

const getProductThemeDoc = (themeKey) => docData(PATH_THEME.doc(themeKey), { idField: 'themeKey' });

const deleteProductTheme = (themeKey) => deleteDoc(PATH_THEME.doc(themeKey));

const updateProductTheme = (themeKey, data) =>
  updateDoc(PATH_THEME.doc(themeKey), {
    ...data,
    themeUpdateDate: serverTimestamp(),
  });

const createProductTheme = async (data) => {
  let email = null;
  let uid = null;

  await onAuthStateChanged(AUTH, async (user) => {
    if (user) {
      email = user.email;
      uid = user.uid;
    }
  });

  const createProductThemePayload = {
    ...data,
    themeCreateDate: serverTimestamp(),
    themeUpdateDate: serverTimestamp(),
    themeAdminUserKey: uid,
    themeAdminEmail: email,
  };

  const docRef = await addDoc(PATH_THEME.root, createProductThemePayload);

  console.log('Document written with ID: ', docRef.id);

  return docRef;
};

export { createProductTheme, getProductThemeList, getProductThemeDoc, deleteProductTheme, updateProductTheme };
