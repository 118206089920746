import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  addDoc,
  serverTimestamp,
  getDocs,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collectionData, docData } from 'rxfire/firestore';

import { DB, AUTH } from './firebase';
// PATH
import { PATH_REVIEW } from './firebase-paths';

const getProductReviewList = () => collectionData(PATH_REVIEW.root, { idField: 'reviewKey' });

const getProductReviewDoc = (reviewKey) => docData(PATH_REVIEW.doc(reviewKey), { idField: 'reviewKey' });

const deleteProductReview = (reviewKey) => deleteDoc(PATH_REVIEW.doc(reviewKey));

const updateProductReview = (reviewKey, data) =>
  updateDoc(PATH_REVIEW.doc(reviewKey), {
    ...data,
    reviewUpdateDate: serverTimestamp(),
  });

const createProductReview = async (data) => {
  let email = null;
  let uid = null;

  await onAuthStateChanged(AUTH, async (user) => {
    if (user) {
      email = user.email;
      uid = user.uid;
    }
  });

  const createProductReviewPayload = {
    ...data,
    reviewCreateDate: serverTimestamp(),
    reviewUpdateDate: serverTimestamp(),
    reviewAdminUserKey: uid,
    reviewAdminEmail: email,
  };

  const docRef = await addDoc(PATH_REVIEW.root, createProductReviewPayload);

  console.log('Document written with ID: ', docRef.id);

  return docRef;
};

export { createProductReview, getProductReviewList, getProductReviewDoc, deleteProductReview, updateProductReview };
