import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { map } from 'rxjs';
// service
import {
  createProductCategory,
  getProductCategoryList,
  getProductCategoryDoc,
  deleteProductCategory,
  updateProductCategory,
} from '../../service/category';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  categories: [],
  category: null,
  createCategory: false,
  updateCategory: false,
  deleteCategory: false,
  sortBy: null,
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CREATE CATEGORY
    createProductCategorySuccess(state, action) {
      state.isLoading = false;
      state.createCategory = true;
    },

    // GET CATEGORIES
    getProductCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.updateCategory = false;
      state.deleteCategory = false;
      state.categories = action.payload;
    },

    // GET CATEGORY
    getProductCategorySuccess(state, action) {
      state.isLoading = false;
      state.updateCategory = false;
      state.deleteCategory = false;
      state.category = action.payload;
    },

    // UPDATE CATEGORY
    updateProductCategorySuccess(state, action) {
      state.isLoading = false;
      state.updateCategory = true;
    },

    // DELETE CATEGORY
    deleteProductCategorySuccess(state, action) {
      state.isLoading = false;
      state.deleteCategory = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function createCategory(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      createProductCategory(data).subscribe((categoryRef) => {
        dispatch(slice.actions.getProductCategoriesSuccess(categoryRef));
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCategories() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      getProductCategoryList().subscribe((categoryList) => {
        dispatch(slice.actions.getProductCategoriesSuccess(categoryList));
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCategoriesDropDown() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      getProductCategoryList()
        .pipe(
          map((categories) => {
            return categories.map((category) => {
              return {
                th: `${category.categoryThaiName}`,
                en: `${category.categoryEngName}`,
              };
            });
          })
        )
        .subscribe((categoryList) => {
          dispatch(slice.actions.getProductCategoriesSuccess(categoryList));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCategory(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      getProductCategoryDoc(id).subscribe((categoryDoc) => {
        dispatch(slice.actions.getProductCategorySuccess(categoryDoc));
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateCategory(id, data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await updateProductCategory(id, data);
      dispatch(slice.actions.updateProductCategorySuccess());
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteCategory(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await deleteProductCategory(id);
      dispatch(slice.actions.deleteProductCategorySuccess());
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
