import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { map } from 'rxjs';
// service
import {
  createProductCoupon,
  getProductCouponList,
  getProductCouponDoc,
  deleteProductCoupon,
  updateProductCoupon,
} from '../../service/coupon';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  coupons: [],
  coupon: null,
  createCoupon: false,
  updateCoupon: false,
  deleteCoupon: false,
  sortBy: null,
};

const slice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CREATE COUPON
    createProductCouponSuccess(state, action) {
      state.isLoading = false;
      state.createCoupon = true;
    },

    // GET COUPONS
    getProductCouponsSuccess(state, action) {
      state.isLoading = false;
      state.updateCoupon = false;
      state.deleteCoupon = false;
      state.coupons = action.payload;
    },

    // GET COUPON
    getProductCouponSuccess(state, action) {
      state.isLoading = false;
      state.updateCoupon = false;
      state.deleteCoupon = false;
      state.coupon = action.payload;
    },

    // UPDATE COUPON
    updateProductCouponSuccess(state, action) {
      state.isLoading = false;
      state.updateCoupon = true;
    },

    // DELETE COUPON
    deleteProductCouponSuccess(state, action) {
      state.isLoading = false;
      state.deleteCoupon = true;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function createCoupon(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      createProductCoupon(data).subscribe((couponRef) => {
        dispatch(slice.actions.createProductCategoriesSuccess(couponRef));
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCoupons() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      getProductCouponList().subscribe((couponList) => {
        dispatch(slice.actions.getProductCouponsSuccess(couponList));
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCoupon(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      getProductCouponDoc(id).subscribe((couponDoc) => {
        dispatch(slice.actions.getProductCouponSuccess(couponDoc));
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateCoupon(id, data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      updateProductCoupon(id, data).subscribe((couponDoc) => {
        dispatch(slice.actions.updateProductCouponSuccess());
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteCoupon(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      deleteProductCoupon(id).subscribe((couponDoc) => {
        dispatch(slice.actions.deleteProductCouponSuccess());
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
