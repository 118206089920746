import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { map } from 'rxjs';
// service
import {
  createProductReview,
  getProductReviewList,
  getProductReviewDoc,
  deleteProductReview,
  updateProductReview,
} from '../../service/review';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  reviews: [],
  review: null,
  createReview: false,
  updateReview: false,
  deleteReview: false,
  sortBy: null,
};

const slice = createSlice({
  name: 'review',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CREATE REVIEW
    createProductReviewSuccess(state, action) {
      state.isLoading = false;
      state.createReview = true;
    },

    // GET REVIEWS
    getProductReviewsSuccess(state, action) {
      state.isLoading = false;
      state.updateReview = false;
      state.deleteReview = false;
      state.reviews = action.payload;
    },

    // GET REVIEW
    getProductReviewSuccess(state, action) {
      state.isLoading = false;
      state.updateReview = false;
      state.deleteReview = false;
      state.review = action.payload;
    },

    // UPDATE REVIEW
    updateProductReviewSuccess(state, action) {
      state.isLoading = false;
      state.updateReview = true;
    },

    // DELETE REVIEW
    deleteProductReviewSuccess(state, action) {
      state.isLoading = false;
      state.deleteReview = true;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function createReview(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      createProductReview(data).subscribe((reviewRef) => {
        dispatch(slice.actions.createProductCategoriesSuccess(reviewRef));
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getReviews() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      getProductReviewList().subscribe((reviewList) => {
        dispatch(slice.actions.getProductReviewsSuccess(reviewList));
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getReview(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      getProductReviewDoc(id).subscribe((reviewDoc) => {
        dispatch(slice.actions.getProductReviewSuccess(reviewDoc));
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateReview(id, data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      updateProductReview(id, data).subscribe((reviewDoc) => {
        dispatch(slice.actions.updateProductReviewSuccess());
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteReview(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      deleteProductReview(id).subscribe((reviewDoc) => {
        dispatch(slice.actions.deleteProductReviewSuccess());
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
