import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  addDoc,
  serverTimestamp,
  getDocs,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collectionData, docData } from 'rxfire/firestore';

import { DB, AUTH } from './firebase';
// PATH
import { PATH_SIZE } from './firebase-paths';

const getProductSizeList = () => collectionData(PATH_SIZE.root, { idField: 'sizeKey' });

const getProductSizeDoc = (sizeKey) => docData(PATH_SIZE.doc(sizeKey), { idField: 'sizeKey' });

const deleteProductSize = (sizeKey) => deleteDoc(PATH_SIZE.doc(sizeKey));

const updateProductSize = async (sizeKey, data) => {
  let email = null;
  let uid = null;

  await onAuthStateChanged(AUTH, async (user) => {
    if (user) {
      email = user.email;
      uid = user.uid;
    }
  });

  const updateProductSizePayload = {
    ...data,
    sizeUpdateDate: serverTimestamp(),
    sizeAdminUserKey: uid,
    sizeAdminEmail: email,
  };

  return updateDoc(PATH_SIZE.doc(sizeKey), updateProductSizePayload);
};

const createProductSize = async (data) => {
  let email = null;
  let uid = null;

  await onAuthStateChanged(AUTH, async (user) => {
    if (user) {
      email = user.email;
      uid = user.uid;
    }
  });

  const createProductSizePayload = {
    ...data,
    sizeCreateDate: serverTimestamp(),
    sizeUpdateDate: serverTimestamp(),
    sizeAdminUserKey: uid,
    sizeAdminEmail: email,
  };

  const docRef = await addDoc(PATH_SIZE.root, createProductSizePayload);

  console.log('Document written with ID: ', docRef.id);

  return docRef;
};

export { createProductSize, getProductSizeList, getProductSizeDoc, deleteProductSize, updateProductSize };
