import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  addDoc,
  serverTimestamp,
  getDocs,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collectionData, docData } from 'rxfire/firestore';

import { DB, AUTH } from './firebase';
// PATH
import { PATH_COUPON } from './firebase-paths';

const getProductCouponList = () => collectionData(PATH_COUPON.root, { idField: 'couponKey' });

const getProductCouponDoc = (couponKey) => docData(PATH_COUPON.doc(couponKey), { idField: 'couponKey' });

const deleteProductCoupon = (couponKey) => deleteDoc(PATH_COUPON.doc(couponKey));

const updateProductCoupon = (couponKey, data) =>
  updateDoc(PATH_COUPON.doc(couponKey), {
    ...data,
    couponUpdateDate: serverTimestamp(),
  });

const createProductCoupon = async (data) => {
  let email = null;
  let uid = null;

  await onAuthStateChanged(AUTH, async (user) => {
    if (user) {
      email = user.email;
      uid = user.uid;
    }
  });

  const createProductCouponPayload = {
    ...data,
    couponCreateDate: serverTimestamp(),
    couponUpdateDate: serverTimestamp(),
    couponAdminUserKey: uid,
    couponAdminEmail: email,
  };

  const docRef = await addDoc(PATH_COUPON.root, createProductCouponPayload);

  console.log('Document written with ID: ', docRef.id);

  return docRef;
};

export { createProductCoupon, getProductCouponList, getProductCouponDoc, deleteProductCoupon, updateProductCoupon };
