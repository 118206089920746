import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  addDoc,
  serverTimestamp,
  getDocs,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collectionData, docData } from 'rxfire/firestore';

import { DB, AUTH } from './firebase';
// PATH
import { PATH_TAG } from './firebase-paths';

const getProductTagList = () => collectionData(PATH_TAG.root, { idField: 'tagKey' });

const getProductTagDoc = (docKey) => docData(PATH_TAG.doc(docKey), { idField: 'tagKey' });

const deleteProductTag = (tagKey) => deleteDoc(PATH_TAG.doc(tagKey));

const updateProductTag = (tagKey, data) =>
  updateDoc(PATH_TAG.doc(tagKey), {
    ...data,
    tagUpdateDate: serverTimestamp(),
  });

const createProductTag = async (data) => {
  let email = null;
  let uid = null;

  await onAuthStateChanged(AUTH, async (user) => {
    if (user) {
      email = user.email;
      uid = user.uid;
    }
  });

  const createProductTagPayload = {
    ...data,
    tagCreateDate: serverTimestamp(),
    tagUpdateDate: serverTimestamp(),
    tagAdminUserKey: uid,
    tagAdminEmail: email,
  };

  const docRef = await addDoc(PATH_TAG.root, createProductTagPayload);

  console.log('Document written with ID: ', docRef.id);

  return docRef;
};

export { createProductTag, getProductTagList, getProductTagDoc, deleteProductTag, updateProductTag };
