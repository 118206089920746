import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  addDoc,
  serverTimestamp,
  getDocs,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collectionData, docData } from 'rxfire/firestore';

import { DB, AUTH } from './firebase';
// PATH
import { PATH_TRANSPORT } from './firebase-paths';

const getProductTransportList = () => collectionData(PATH_TRANSPORT.root, { idField: 'transportKey' });

const getProductTransportDoc = (transportKey) => docData(PATH_TRANSPORT.doc(transportKey), { idField: 'transportKey' });

const deleteProductTransport = (transportKey) => deleteDoc(PATH_TRANSPORT.doc(transportKey));

const updateProductTransport = (transportKey, data) =>
  updateDoc(PATH_TRANSPORT.doc(transportKey), {
    ...data,
    transportUpdateDate: serverTimestamp(),
  });

const createProductTransport = async (data) => {
  let email = null;
  let uid = null;

  await onAuthStateChanged(AUTH, async (user) => {
    if (user) {
      email = user.email;
      uid = user.uid;
    }
  });

  const createProductTransportPayload = {
    ...data,
    transportCreateDate: serverTimestamp(),
    transportUpdateDate: serverTimestamp(),
    transportAdminUserKey: uid,
    transportAdminEmail: email,
  };

  const docRef = await addDoc(PATH_TRANSPORT.root, createProductTransportPayload);

  console.log('Document written with ID: ', docRef.id);

  return docRef;
};

export {
  createProductTransport,
  getProductTransportList,
  getProductTransportDoc,
  deleteProductTransport,
  updateProductTransport,
};
