import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { map } from 'rxjs';
// service
import {
  createProductDuration,
  getProductDurationList,
  getProductDurationDoc,
  deleteProductDuration,
  updateProductDuration,
} from '../../service/duration';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  durations: [],
  duration: null,
  createDuration: false,
  updateDuration: false,
  deleteDuration: false,
  sortBy: null,
};

const slice = createSlice({
  name: 'duration',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CREATE DURATION
    createProductDurationSuccess(state, action) {
      state.isLoading = false;
      state.createDuration = true;
    },

    // GET DURATIONS
    getProductDurationsSuccess(state, action) {
      state.isLoading = false;
      state.updateDuration = false;
      state.deleteDuration = false;
      state.durations = action.payload;
    },

    // GET DURATION
    getProductDurationSuccess(state, action) {
      state.isLoading = false;
      state.updateDuration = false;
      state.deleteDuration = false;
      state.duration = action.payload;
    },

    // UPDATE DURATION
    updateProductDurationSuccess(state, action) {
      state.isLoading = false;
      state.updateDuration = true;
    },

    // DELETE DURATION
    deleteProductDurationSuccess(state, action) {
      state.isLoading = false;
      state.deleteDuration = true;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function createDuration(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      createProductDuration(data).subscribe((durationRef) => {
        dispatch(slice.actions.createProductDurationSuccess(durationRef));
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDurations() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      getProductDurationList().subscribe((durationList) => {
        dispatch(slice.actions.getProductDurationsSuccess(durationList));
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDuration(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      getProductDurationDoc(id).subscribe((durationDoc) => {
        dispatch(slice.actions.getProductDurationSuccess(durationDoc));
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateDuration(id, data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      updateProductDuration(id, data).subscribe((durationDoc) => {
        dispatch(slice.actions.updateProductDurationSuccess());
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteDuration(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      deleteProductDuration(id).subscribe((durationDoc) => {
        dispatch(slice.actions.deleteProductDurationSuccess());
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
