import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  addDoc,
  serverTimestamp,
  getDocs,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collectionData, docData } from 'rxfire/firestore';

import { DB, AUTH } from './firebase';
// PATH
import { PATH_DURATION } from './firebase-paths';

const getProductDurationList = () => collectionData(PATH_DURATION.root, { idField: 'durationKey' });

const getProductDurationDoc = (docKey) => docData(PATH_DURATION.doc(docKey), { idField: 'durationKey' });

const deleteProductDuration = (durationKey) => deleteDoc(PATH_DURATION.doc(durationKey));

const updateProductDuration = (durationKey, data) =>
  updateDoc(PATH_DURATION.doc(durationKey), {
    ...data,
    durationUpdateDate: serverTimestamp(),
  });

const createProductDuration = async (data) => {
  let email = null;
  let uid = null;

  await onAuthStateChanged(AUTH, async (user) => {
    if (user) {
      email = user.email;
      uid = user.uid;
    }
  });

  const createProductDurationPayload = {
    ...data,
    durationCreateDate: serverTimestamp(),
    durationUpdateDate: serverTimestamp(),
    durationAdminUserKey: uid,
    durationAdminEmail: email,
  };

  const docRef = await addDoc(PATH_DURATION.root, createProductDurationPayload);

  console.log('Document written with ID: ', docRef.id);

  return docRef;
};

export {
  createProductDuration,
  getProductDurationList,
  getProductDurationDoc,
  deleteProductDuration,
  updateProductDuration,
};
