import { collection, doc } from 'firebase/firestore';
import { DB, AUTH } from './firebase';

// ----------------------------------------------------------------------

const collectionPath = (root) => collection(DB, `${root}`);

const docPath = (root, docKey) => {
  return doc(DB, `${root}/${docKey}`);
};

const subCollectionPath = (root, docKey, subCollection) => {
  return collection(DB, `${root}/${docKey}/${subCollection}`);
};

const subCollectionDocPath = (root, docKey, subCollection, subCollectionDoc) => {
  return doc(DB, `${root}/${docKey}/${subCollection}/${subCollectionDoc}`);
};

const ROOTS_CATEGORY = 'category';
const ROOTS_TAG = 'tag';
const ROOTS_SIZE = 'size';
const ROOTS_COLOR = 'color';
const ROOTS_THEME = 'theme';
const ROOTS_PAYMENT = 'payment';
const ROOTS_REVIEW = 'review';
const ROOTS_DURATION = 'duration';
const ROOTS_TRANSPORT = 'transport';
const ROOTS_PRODUCT = 'product';
const ROOTS_ACCESSORY = 'accessory';
const ROOTS_BOOKING = 'booking';
const ROOTS_USER = 'user';
const ROOTS_ADMIN = 'admin';
const ROOTS_CHAT = 'chat';
const ROOTS_COUPON = 'coupon';
const ROOTS_DASHBOARD = 'dashboard';

// ----------------------------------------------------------------------

export const PATH_CATEGORY = {
  root: collectionPath(ROOTS_CATEGORY),
  doc: (docKey) => docPath(ROOTS_CATEGORY, docKey),
};

export const PATH_TAG = {
  root: collectionPath(ROOTS_TAG),
  doc: (docKey) => docPath(ROOTS_TAG, docKey),
};
export const PATH_SIZE = {
  root: collectionPath(ROOTS_SIZE),
  doc: (docKey) => docPath(ROOTS_SIZE, docKey),
};
export const PATH_COLOR = {
  root: collectionPath(ROOTS_COLOR),
  doc: (docKey) => docPath(ROOTS_COLOR, docKey),
};
export const PATH_THEME = {
  root: collectionPath(ROOTS_THEME),
  doc: (docKey) => docPath(ROOTS_THEME, docKey),
};
export const PATH_PAYMENT = {
  root: collectionPath(ROOTS_PAYMENT),
  doc: (docKey) => docPath(ROOTS_PAYMENT, docKey),
};
export const PATH_REVIEW = {
  root: collectionPath(ROOTS_REVIEW),
  doc: (docKey) => docPath(ROOTS_REVIEW, docKey),
};
export const PATH_DURATION = {
  root: collectionPath(ROOTS_DURATION),
  doc: (docKey) => docPath(ROOTS_DURATION, docKey),
};
export const PATH_TRANSPORT = {
  root: collectionPath(ROOTS_TRANSPORT),
  doc: (docKey) => docPath(ROOTS_TRANSPORT, docKey),
};
export const PATH_PRODUCT = {
  root: collectionPath(ROOTS_PRODUCT),
  doc: (docKey) => docPath(ROOTS_PRODUCT, docKey),
  review: (docKey) => subCollectionPath(ROOTS_PRODUCT, docKey, 'productReview'),
};
export const PATH_ACCESSORY = {
  root: collectionPath(ROOTS_ACCESSORY),
  doc: (docKey) => docPath(ROOTS_ACCESSORY, docKey),
};
export const PATH_BOOKING = {
  root: collectionPath(ROOTS_BOOKING),
  doc: (docKey) => docPath(ROOTS_BOOKING, docKey),
};
export const PATH_USER = {
  root: collectionPath(ROOTS_USER),
  doc: (docKey) => docPath(ROOTS_USER, docKey),
  cart: (docKey) => subCollectionPath(ROOTS_USER, docKey, 'userCart'),
};
export const PATH_ADMIN = {
  root: collectionPath(ROOTS_ADMIN),
  doc: (docKey) => docPath(ROOTS_ADMIN, docKey),
};
export const PATH_CHAT = {
  root: collectionPath(ROOTS_CHAT),
  doc: (docKey) => docPath(ROOTS_CHAT, docKey),
  message: (docKey) => subCollectionPath(ROOTS_CHAT, docKey, 'message'),
};
export const PATH_COUPON = {
  root: collectionPath(ROOTS_COUPON),
  doc: (docKey) => docPath(ROOTS_COUPON, docKey),
};
export const PATH_DASHBOARD = {
  root: collectionPath(ROOTS_DASHBOARD),
  doc: (docKey) => docPath(ROOTS_DASHBOARD, docKey),
};
