import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { map } from 'rxjs';
// service
import {
  createProductTag,
  getProductTagList,
  getProductTagDoc,
  deleteProductTag,
  updateProductTag,
} from '../../service/tag';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  tags: [],
  tag: null,
  createTag: false,
  updateTag: false,
  deleteTag: false,
  sortBy: null,
};

const slice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CREATE TAG
    createProductTagSuccess(state, action) {
      state.isLoading = false;
      state.createTag = true;
    },

    // GET TAGS
    getProductTagsSuccess(state, action) {
      state.isLoading = false;
      state.updateTag = false;
      state.deleteTag = false;
      state.tags = action.payload;
    },

    // GET TAG
    getProductTagSuccess(state, action) {
      state.isLoading = false;
      state.updateTag = false;
      state.deleteTag = false;
      state.tag = action.payload;
    },

    // UPDATE TAG
    updateProductTagSuccess(state, action) {
      state.isLoading = false;
      state.updateTag = true;
    },

    // DELETE TAG
    deleteProductTagSuccess(state, action) {
      state.isLoading = false;
      state.deleteTag = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function createTag(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      createProductTag(data).subscribe((tagRef) => {
        dispatch(slice.actions.createProductTagSuccess(tagRef));
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTagsDropDown() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      getProductTagList()
        .pipe(
          map((tagList) => {
            return tagList.map((tag) => {
              return {
                th: `${tag.tagThaiName}`,
                en: `${tag.tagEngName}`,
              };
            });
          })
        )
        .subscribe((tagList) => {
          dispatch(slice.actions.getProductTagsSuccess(tagList));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTags() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      getProductTagList().subscribe((tagList) => {
        dispatch(slice.actions.getProductTagsSuccess(tagList));
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTag(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      getProductTagDoc(id).subscribe((tagDoc) => {
        dispatch(slice.actions.getProductTagSuccess(tagDoc));
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateTag(id, data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      updateProductTag(id, data).subscribe((tagDoc) => {
        dispatch(slice.actions.updateProductTagSuccess());
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteTag(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      deleteProductTag(id).subscribe((tagDoc) => {
        dispatch(slice.actions.deleteProductTagSuccess());
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
