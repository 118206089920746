import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  addDoc,
  serverTimestamp,
  getDocs,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collectionData, docData } from 'rxfire/firestore';

import { DB, AUTH } from './firebase';
// PATH
import { PATH_PRODUCT } from './firebase-paths';

const createProduct = async (data) => {
  let email = null;
  let uid = null;

  await onAuthStateChanged(AUTH, async (user) => {
    if (user) {
      email = user.email;
      uid = user.uid;
    }
  });

  const createProductPayload = {
    ...data,
    productCreateDate: serverTimestamp(),
    productUpdateDate: serverTimestamp(),
    productAdminUserKey: uid,
    productAdminEmail: email,
  };

  const docRef = await addDoc(PATH_PRODUCT.root, createProductPayload);

  console.log('Document written with ID: ', docRef.id);

  return docRef;
};

const getProductList = () => collectionData(PATH_PRODUCT.root, { idField: 'productKey' });

const getProductDoc = (docKey) => docData(PATH_PRODUCT.doc(docKey), { idField: 'productKey' });

const deleteProduct = (productKey) => deleteDoc(PATH_PRODUCT.doc(productKey));

const updateProduct = (productKey, data) =>
  updateDoc(PATH_PRODUCT.doc(productKey), {
    ...data,
    productUpdateDate: serverTimestamp(),
  });

export { createProduct, getProductList, getProductDoc, deleteProduct, updateProduct };
