import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  addDoc,
  serverTimestamp,
  getDocs,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collectionData, docData } from 'rxfire/firestore';

import { DB, AUTH } from './firebase';
// PATH
import { PATH_PAYMENT } from './firebase-paths';

const getProductPaymentList = () => collectionData(PATH_PAYMENT.root, { idField: 'paymentKey' });

const getProductPaymentDoc = (paymentKey) => docData(PATH_PAYMENT.doc(paymentKey), { idField: 'paymentKey' });

const deleteProductPayment = (paymentKey) => deleteDoc(PATH_PAYMENT.doc(paymentKey));

const updateProductPayment = (paymentKey, data) =>
  updateDoc(PATH_PAYMENT.doc(paymentKey), {
    ...data,
    paymentUpdateDate: serverTimestamp(),
  });

const createProductPayment = async (data) => {
  let email = null;
  let uid = null;

  await onAuthStateChanged(AUTH, async (user) => {
    if (user) {
      email = user.email;
      uid = user.uid;
    }
  });

  const createProductPaymentPayload = {
    ...data,
    paymentCreateDate: serverTimestamp(),
    paymentUpdateDate: serverTimestamp(),
    paymentAdminUserKey: uid,
    paymentAdminEmail: email,
  };

  const docRef = await addDoc(PATH_PAYMENT.root, createProductPaymentPayload);

  console.log('Document written with ID: ', docRef.id);

  return docRef;
};

export {
  createProductPayment,
  getProductPaymentList,
  getProductPaymentDoc,
  deleteProductPayment,
  updateProductPayment,
};
