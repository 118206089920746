import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { map } from 'rxjs';
// service
import {
  createProductPayment,
  getProductPaymentList,
  getProductPaymentDoc,
  deleteProductPayment,
  updateProductPayment,
} from '../../service/payment';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  payments: [],
  payment: null,
  createPayment: false,
  updatePayment: false,
  deletePayment: false,
  sortBy: null,
};

const slice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CREATE PAYMENT
    createProductPaymentSuccess(state, action) {
      state.isLoading = false;
      state.createPayment = true;
    },

    // GET PAYMENTS
    getProductPaymentsSuccess(state, action) {
      state.isLoading = false;
      state.updatePayment = false;
      state.deletePayment = false;
      state.payments = action.payload;
    },

    // GET PAYMENT
    getProductPaymentSuccess(state, action) {
      state.isLoading = false;
      state.updatePayment = false;
      state.deletePayment = false;
      state.payment = action.payload;
    },

    // UPDATE PAYMENT
    updateProductPaymentSuccess(state, action) {
      state.isLoading = false;
      state.updatePayment = true;
    },

    // DELETE PAYMENT
    deleteProductPaymentSuccess(state, action) {
      state.isLoading = false;
      state.deletePayment = true;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function createPayment(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      createProductPayment(data).subscribe((paymentRef) => {
        dispatch(slice.actions.createProductPaymentSuccess(paymentRef));
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPayments() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      getProductPaymentList().subscribe((paymentList) => {
        dispatch(slice.actions.getProductPaymentsSuccess(paymentList));
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPayment(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      getProductPaymentDoc(id).subscribe((paymentDoc) => {
        dispatch(slice.actions.getProductPaymentSuccess(paymentDoc));
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updatePayment(id, data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      updateProductPayment(id, data).subscribe((paymentDoc) => {
        dispatch(slice.actions.updateProductPaymentSuccess());
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deletePayment(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      deleteProductPayment(id).subscribe((paymentDoc) => {
        dispatch(slice.actions.deleteProductPaymentSuccess());
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
